<!-- 快递单号计费 -->
<template>
  <div class="logistics-list">
    <div class="list-container">
      <div class="search-container">
        <div class="sc-item">
          <label class="sci-title">方案名称</label>
          <el-input
            placeholder="请输入方案名称"
            v-model="searchData.rules_name"
            clearable
          >
          </el-input>
        </div>
        <div class="sc-item">
          <label class="sci-title">快递公司</label>
          <el-select
            v-model="searchData.delivery_type"
            filterable
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in deliveryTypes"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </div>
        <div class="sc-item">
          <label class="sci-title">方案级别</label>
          <el-radio-group v-model="searchData.rules_type" size="medium">
            <el-radio-button v-for="rtype in rulesType" :label="rtype.key" :key="rtype.key">{{rtype.value}}</el-radio-button>
          </el-radio-group>
        </div>
        <div class="sc-item">
          <el-button @click="handleSearch" icon="el-icon-search" type="primary"
            >查询</el-button
          >
          <el-button @click="handleReset" icon="el-icon-refresh-left"
            >重置</el-button
          >
          <el-button type="success" @click="handleAdd" icon="el-icon-plus">新增方案</el-button>
          <el-button type="danger" @click="handleDel" icon="el-icon-delete">删除</el-button>
        </div>
      </div>
      <div class="table-container">
        <el-table
          :data="tableData"
          border
          @selection-change="handleSelectionChange"
          header-cell-class-name="table-header"
        >  <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            type="index"
            :index="calcIndex"
            label="序号"
            width="50"
            fixed
          >
          </el-table-column>
          <el-table-column fixed prop="rules_name" label="方案名称">
          </el-table-column>
          <el-table-column label="方案级别">
            <template slot-scope="scope">
              {{ scope.row.rules_type == 0 ? '客户方案' : '子代理方案' }}
            </template>
          </el-table-column>
          <el-table-column prop="delivery_name" label="快递公司">
            <template slot-scope="scope">
              <template v-if="scope.row.value_obj">
                <el-tooltip placement="top" effect="light">
                    <div slot="content" class="tooltip">
                      <div class="line" v-for="item in scope.row.value_obj" :key="item.delivery">
                        <div class="line-title">{{ item.deliveryName }}</div>
                        <div class="line-sign">
                          <div v-for="(sign, index) in item.rulessigns" :key="index" class="info-box">
                            <div class="sign">{{ sign.key }}</div>
                            <div>起始重量：<span>{{ sign.base_weight }}</span></div>
                            <div>加价重量：<span>{{ sign.add_weight }}</span></div>
                            <div>起始价格：<span>{{ sign.base_price }}</span></div>
                            <div>加价价格：<span>{{ sign.add_price }}</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  <span>{{ scope.row.delivery_name }}</span>
                </el-tooltip>
              </template>
              <template v-else>{{ scope.row.delivery_name }}</template>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.create_time | dateFormat('YYYY-MM-DD HH:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120px">
            <template slot-scope="scope">
              <div style="display: flex; justify-content: space-around;">
                <el-link :underline="false" @click="handleInfo(scope.row)">详情</el-link>
                <el-link :underline="false" @click="handleEdit(scope.row)">编辑</el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="pageLayout"
        :total="tableTotal"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="1000px"
      :before-close="handleClose">
      <div>
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px">
          <el-form-item label="方案名称" prop="rulesName">
            <el-input v-model="form.rulesName" placeholder="方案名称" :disabled="isLook"></el-input>
          </el-form-item>
          <el-form-item label="方案级别" prop="rulesType">
            <el-radio-group v-model="form.rulesType" size="medium" :disabled="isLook">
              <el-radio-button v-for="rtype in rulesType" :label="rtype.key" :key="rtype.key">{{rtype.value}}</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="快递公司" prop="rulesDelivery">
            <el-checkbox-group v-model="form.rulesDelivery" size="medium" @change="changeDelivery" :disabled="isLook">
              <el-checkbox v-for="item in deliveryTypes" :label="item.key" :key="item.key">{{item.value}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <template>
            <div  v-for="(item, valindex) in form.rulesVal" :key="item.delivery">
              <div class="delivery-name">{{ item.deliveryName }}</div>
              <div class="sign-line" v-for="(sign, index) in item.rulessigns" :key="sign.key">
                <div class="sign">{{ sign.key }}</div>
                <el-row>
                  <el-col :span="6">
                    <el-form-item label="起始重量"
                    :prop="'rulesVal.' + valindex + '.rulessigns.' + index + '.base_weight'"
                    :rules="[
                      {required: true, message: '起始重量不能为空', trigger: 'blur'},
                      { pattern: /^\d+(\.\d{1,2})?$/, message: '只能输入非负整数字，最多两位小数', trigger: 'blur'}
                    ]">
                      <el-input type="number" v-model="sign.base_weight" :disabled="isLook"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="加价重量"
                    :prop="'rulesVal.' + valindex + '.rulessigns.' + index + '.add_weight'"
                    :rules="[
                      {required: true, message: '加价重量不能为空', trigger: 'blur'},
                      { pattern: /^\d+(\.\d{1,2})?$/, message: '只能输入非负整数字，最多两位小数', trigger: 'blur'}
                    ]">
                      <el-input type="number" v-model="sign.add_weight" :disabled="isLook"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="起始价格"
                    :prop="'rulesVal.' + valindex + '.rulessigns.' + index + '.base_price'"
                    :rules="[
                      {required: true, message: '起始价格不能为空', trigger: 'blur'},
                      { pattern: /^\d+(\.\d{1,2})?$/, message: '只能输入非负整数字，最多两位小数', trigger: 'blur'}
                    ]">
                      <el-input type="number" v-model="sign.base_price" :disabled="isLook"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="加价价格"
                    :prop="'rulesVal.' + valindex + '.rulessigns.' + index + '.add_price'"
                    :rules="[
                      {required: true, message: '加价价格不能为空', trigger: 'blur'},
                      { pattern: /^\d+(\.\d{1,2})?$/, message: '只能输入非负整数字，最多两位小数', trigger: 'blur'}
                    ]">
                      <el-input type="number" v-model="sign.add_price" :disabled="isLook"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
          </template>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button v-if="!isLook" type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { PAGESIZELIST, PAGE_LAYOUT, PAGESIZE } from 'config/index'
import FinanceApi from 'api/finance.js'
import Commonbill from 'api/common'
import { GetToken } from 'utils/auth'
import tableIndex from '@/mixins/table-index'
export default {
  components: {},
  name: '',
  mixins: [tableIndex],
  data() {
    return {
      currentPage: 1,
      pageSize: PAGESIZE,
      pageLayout: PAGE_LAYOUT,
      pageSizes: PAGESIZELIST,
      rulesType:[{key:0, value:'客户方案'}, {key:1, value:'子代理方案'}],
      dialogVisible: false,
      dialogTitle: '',
      signData:['A', 'B', 'C', 'X', 'Y', 'Z'],
      searchData: {
        rules_name: '', // 方案名称
        delivery_type: '', // 快递公司
        rules_type: '' // 方案级别
      },
      deliveryTypes: [], // 枚举快递公司
      tableData: [],
      tableTotal: 0,
      form:{
        id:0,
        rulesName:'',
        rulesType:'',
        rulesDelivery:[],
        rulesVal:[],
      },
      rules:{
        rulesName: [
          {required: true, message: '输入方案名称', trigger: 'blur'}
        ],
        rulesType: [
          {required: true, message: '选择方案级别', trigger: 'change'}
        ],
        rulesDelivery: [
          {required: true, message: '选择快递', trigger: 'change'}
        ]
      },
      selRow:[],
      isLook: false,
      agencyCode: this.$route.query.agency // 代理商自身
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getDeliveryTypes()
    this.getList()
  },
  mounted() {},
  destroyed() {},
  methods: {
    handleSelectionChange(val){
      this.selRow = []
      if(val && val.length > 0) {
        this.selRow = val.map(item => {
          return item.id
        })
      }
    },
    // 详情
    handleInfo(obj){
      this.isLook = true
      this.form.rulesName = obj.rules_name
      this.form.rulesType = obj.rules_type
      this.form.rulesDelivery = obj.delivery_type.split(',').filter(function(item) {
        return item !== '';
      })
      this.form.rulesVal = obj.value_obj
      this.dialogVisible = true
    },
    // 添加
    handleAdd(){
      this.dialogVisible = true
    },
    // 编辑
    handleEdit(obj){
      this.isLook = false
      this.form.id = obj.id
      this.form.rulesName = obj.rules_name
      this.form.rulesType = obj.rules_type
      this.form.rulesDelivery = obj.delivery_type.split(',').filter(function(item) {
        return item !== '';
      })
      this.form.rulesVal = obj.value_obj
      this.dialogVisible = true
    },
    // 删除
    handleDel() {
      let _self = this
      if(this.selRow && this.selRow.length>0){
        FinanceApi.delFreightRules({token :GetToken(), key: this.selRow.join(',')}).then(res => {
          if(res.success) {
              _self.$message.success('操作成功')
              _self.getList()
            } else {
              this.$message.error(res.message)
            }
        })
      } else {
        this.$message.error('选择要删除的项')
      }
    },
    // 关闭
    handleClose(){
      this.form = {
        id:0,
        rulesName:'',
        rulesType:'',
        rulesDelivery:[],
        rulesVal:[],
      }
      this.isLook = false
      this.dialogVisible = false
    },
    getParams(){
      let _params = {}
      _params.token = GetToken(),
      _params.agency = this.agencyCode
      _params.rulesName = this.form.rulesName
      _params.rulesType = this.form.rulesType
      _params.id = this.form.id
      _params.rulesDelivery = this.form.rulesDelivery.join(',') + ','
      _params.rulesVal = JSON.stringify(this.form.rulesVal)
      return _params
    },
    // 提交
    handleSubmit(){
      let _self = this
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = this.getParams()
          FinanceApi.addFreightRules(params).then(res => {
            if(res.success) {
              _self.$message.success('操作成功')
              _self.handleClose()
              _self.getList()
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    changeDelivery(){
      if(this.form.rulesDelivery) {
        this.form.rulesVal.map((v,index) => {
          let _inclues = this.form.rulesDelivery.some(r => {
            return r == v.delivery
          })
          if(!_inclues) {
            this.form.rulesVal.splice(index,1)
          }
        })
        this.form.rulesDelivery.map(item => {
          let _inclues = this.form.rulesVal.some(r => {
           return r.delivery == item
          })
          if(!_inclues){
            let _deliverytype = this.deliveryTypes.find(d => {return d.key == item})
            let _dn = ''
            if(_deliverytype){
              _dn = _deliverytype.value
            }
            this.form.rulesVal.unshift({
              delivery:item,
              deliveryName: _dn,
              rulessigns:this.rulesSings()
            })
          }
        })
      }
      console.log(this.form.rulesVal,'rulesVal')
    },
    rulesSings(){
      let _rval = []
      this.signData.map(item => {
        _rval.push({
          key:item,
          base_price:0,
          add_price:0,
          base_weight:0,
          add_weight:0
        })
      })
      return _rval
    },
    // 查询
    handleSearch() {
      this.currentPage = 1
      this.tableData = []
      this.getList()
    },
    // 重置
    handleReset() {
      this.currentPage = 1
      this.searchData = {
        rules_name: '', // 方案名称
        delivery_type: '', // 快递公司
        rules_type: '' // 方案级别
      }
      this.tableData = []
      this.getList()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.currentPage = 1
      this.pageSize = val
      this.tableData = []
      this.getList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.tableData = []
      this.getList()
    },
    getBaseParams() {
      return {
        token: GetToken(),
        rules_name: this.searchData.rules_name,
        delivery_type: this.searchData.delivery_type,
        rules_type: this.searchData.rules_type,
        agency:this.agencyCode
      }
    },
    getList() {
      FinanceApi.getFreightList({
        ...this.getBaseParams(),
        curr: this.currentPage,
        pageSize: this.pageSize
      }).then((res) => {
        this.tableData = res.record
        this.tableTotal = res.total
        if (res.success) {
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getDeliveryTypes() {
      Commonbill.getDeliveryType({
        token: GetToken()
      }).then((res) => {
        this.deliveryTypes = res.record
        if (res.success) {
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.delivery-total {
  background: #fff;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  span {
    color: red;
    font-size: 16px;
    font-weight: bold;
    display: block;
    margin: 0 5px;
  }
}
.delivery-name{
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}
.sign-line{
  background: #f8f8f8f8;
  padding: 10px;
  margin-bottom:10px;
  border-radius: 4px;
  .sign{
    color: brown;
    font-weight: bold;
    margin-left: 14px;
  }
}
.tooltip{
  width: 900px;
  margin: auto;
  margin-top: 20px;
  text-align: center;
  .line{
    display: flex;
    flex-direction: column;
    .line-title{
      font-weight: bold;
      font-size: 16px !important;
      text-align: center;
    }
    .line-sign{
      margin: 20px 0;
      display: flex;
      .info-box{
        width: 100px !important;
        background: #f8f8f8;
        border: 1px solid #eee;
        padding: 10px 20px 20px;
        margin-right: 20px;
        border-radius: 4px;
        text-align: left;
        &>div{
          margin-bottom: 10px;
        }
        .sign{
          color: brown;
          font-weight: bold;
          text-align: center;
          font-size: 16px !important;
          margin: 5px 0 15px;
        }
        span{
          font-weight: bold;
        }
      }
      .info-box:last-of-type{
        margin-right: 0;
      }
    }
  }
}
</style>
