import { netpost } from '../plugins/request'

export default {
  // 获取列表
  getFreightList(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetFreightList`, params)
  },
  // 添加收费配置
  addFreightRules(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/AddFreightRules`, params)
  },
  // 删除收费配置
  delFreightRules(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/DelFreightRules`, params)
  }
}
